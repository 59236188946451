<template>
  <div>
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          shaped
          top
          v-model="snackbarStatus"
          :timeout="snackbarTimeout"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <h3>تصاویر</h3>
    <v-divider class="mt-4 mb-4"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-data-table
            align="center"
            :headers="headers"
            :items="images.data"
            :items-per-page="10"
            class="elevation-5"
            hide-default-footer
        >
          <template v-slot:item.original_path_url="{item}">
            <img class="mt-1" :src="item.original_path_url" width="100%">
          </template>
          <template v-slot:item.edited_path_url="{item}">
            <img class="mt-1" :src="item.edited_path_url" width="100%">
          </template>
          <template v-slot:item.actions="{item}">
            <v-btn x-small fab color="#EC255A" @click="selectedItem = item; dialog = true"><v-icon color="white">mdi-close</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12">
        <v-pagination
            color="#161853"
            v-model="images.current_page"
            :length="images.last_page"
            circle
            @input="getImages"
        ></v-pagination>
      </v-col>
    </v-row>

    <!--   Dialog   -->
    <v-dialog
        v-if="selectedItem"
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-title>
          آیا از حذف این تصویر اطمینان دارید؟
        </v-card-title>

        <v-card-text>
          <img v-if="selectedItem.edited_path_url" :src="selectedItem.edited_path_url" class="dialog-image">
          <img v-else :src="selectedItem.original_path_url" class="dialog-image">
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="#292C6D"
              text
              @click="deleteImage"
          >
            بله
          </v-btn>
          <v-btn
              color="#EC255A"
              text
              @click="dialog = false"
          >
            خیر
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'Images',

  data() {
    return {
      images: [],

      headers: [
        {text: 'شناسه', value: 'id', width: '10%', align: 'center'},
        {text: 'فایل اصلی', value: 'original_path_url', width: '35%', align: 'center'},
        {text: 'نوع ویرایش', value: 'edit_type', width: '10%', align: 'center'},
        {text: 'فایل ویرایش شده', value: 'edited_path_url', width: '35%', align: 'center'},
        {text: 'عملیات', value: 'actions', width: '10%', align: 'center'},
      ],

      selectedItem: null,
      dialog: false,
    }
  },

  methods: {
    getImages() {
      let page = this.images.current_page ?? 1
      window.axios
          .get('/user/images/index?page=' + page)
          .then((res) => {
            this.images = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
    },

    deleteImage() {
      window.axios
          .post('/user/images/delete/' + this.selectedItem.id)
          .then(() => {
            this.openSnackbar('تصویر مورد نظر حذف شد', 'success')
            this.getImages()
          })
          .catch((err) => {
            console.log(err)
          })
      this.dialog = false
      this.selectedItem = null
    }
  },

  mounted() {
    this.getImages()
  }
}
</script>

<style scoped>
.dialog-image{
  max-width: 450px;
  max-height: 300px;
  height: auto;
  width: auto;
  display: block;
  margin: auto;
  border-radius: 3px;
}
</style>